const state = () => ({
    all: {CallNumber: "99999999999999999"}
})

// getters
const getters = {
    getCallPhone: state => {
        return state.all.CallNumber
    }
}

// actions
const actions = {
    async setCallPhone(context, CallNumber) {
        context.commit('SET_CALL_PHONE', CallNumber)
        //console.log("setCallPhone", CallNumber)

    }
}

// mutations
const mutations = {
    CLEAR_ALL(state) {
        state.all = {}
    },
    SET_CALL_PHONE(state, CallNumber) {
        state.all.CallNumber = CallNumber
        //console.log("SET_CALL_PHONE", CallNumber)

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}