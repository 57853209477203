const defaultRedirect = "companies"

export default {
    install(app, _options) {

        const http = app.config.globalProperties.$http

        const auth = {
            async logout() {
                const router = app.config.globalProperties.$router
                router.currentRoute.value.name !== 'login' && router.push({ name: 'login' })
                await http.logout()
                app.config.globalProperties.$store.commit('user/CLEAR_ALL')
                app.config.globalProperties.$store.commit('ranges/CLEAR_ALL')
            },

            async login(requestData, redirect = defaultRedirect) {
                const { email: login, password } = requestData
                const { success, message, data } = await http.login({ login, password })
                                
                if (success && redirect) {
                    const router = app.config.globalProperties.$router
                    setTimeout(() => router.push({ name: redirect, }), 1000);
                }
                return { success, message }
            },
        }

        app.config.globalProperties.$axios.interceptors.request.use(
            config => config,
            error => Promise.reject(error)
        )
        app.config.globalProperties.$axios.interceptors.response.use(
            response => response,
            error => (error.response.status === 403 && auth.logout(), Promise.reject(error))
        )

        app.config.globalProperties.$auth = auth
        app.provide('auth', auth)
    },
}