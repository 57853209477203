<template>
  <mo-confirmation-modal
    v-if="showDeleteConfirmation"
    @cancel="showDeleteConfirmation = false"
    @submit="deleteItem"
    text="Вы точно хотите удалить эту компанию? Точно-точно?"
  />
  <mo-modal v-else @toggle="toggle" title="Действия над записью">
    <div class="modal-form">
      <div>
        <span>ID:</span>
        <mo-input-number v-model="Id" :disabled="true" />
      </div>
      <div>
        <span>Наименование:</span>
        <mo-input
          v-model="Fulltitle"
          placeholder="Наименование"
          :disabled="true"
        />
      </div>
      <div>
        <span>ИНН:</span>
        <mo-input v-model="Inn" placeholder="ИНН" :disabled="true" />
      </div>
      <mo-checkbox v-model="JobIsDone" :disabled="disableProtected">
        Выполнено
      </mo-checkbox>
    </div>
    <template v-slot:footer>
      <mo-loader v-if="loading" />
      <div v-else class="modal-buttons">
        <mo-button @click="toggle(false)" type="success"> Отменить </mo-button>
        <mo-button @click="saveItem()" type="alert" :disabled="disableSave" 
          v-if="policy['modal_companies_button_save']" id="modal_companies_button_save"
        >
          Сохранить
        </mo-button>
        <mo-button
          @click="showDeleteConfirmation = true"
          type="alert"
          :disabled="disableDelete"
          v-if="policy['modal_companies_button_delete']" id="modal_companies_button_delete"
        >
          Удалить
        </mo-button>
      </div>
    </template>
  </mo-modal>
</template>

<script>
export default {
  name: "mo-companies-modal",
  emits: ["update:item", "cancel", "create", "save", "delete"],
  props: {
    item: {
      type: Object,
    },
    role: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      policy: this.$store.getters["policy/getPolicy"],
      changedItem: {
        Id: undefined,
        Fulltitle: undefined,
        Inn: undefined,
        JobIsDone: undefined,
      },
      showDeleteConfirmation: false,
    };
  },
  computed: {
    Id: {
      get() {
        return this.changedItem.Id !== undefined
          ? this.changedItem.Id
          : this.item.Id;
      },
      set(newValue) {
        return (this.changedItem.Id = newValue);
      },
    },
    Fulltitle: {
      get() {
        return (
          (this.changedItem.Fulltitle !== undefined
            ? this.changedItem.Fulltitle
            : this.item.Fulltitle) ?? ""
        );
      },
      set(newValue) {
        return (this.changedItem.Fulltitle = newValue);
      },
    },
    Inn: {
      get() {
        return (
          (this.changedItem.Inn !== undefined
            ? this.changedItem.Inn
            : this.item.Inn) ?? ""
        );
      },
      set(newValue) {
        return (this.changedItem.Inn = newValue);
      },
    },
    JobIsDone: {
      get() {
        return (
          (this.changedItem.JobIsDone !== undefined
            ? this.changedItem.JobIsDone
            : this.item.JobIsDone) ?? false
        );
      },
      set(newValue) {
        return (this.changedItem.JobIsDone = newValue);
      },
    },
    changes() {
      const changes = {};
      Object.keys(this.changedItem).forEach((fieldName) => {
        const fieldValue = this.changedItem[fieldName];
        if (fieldValue != undefined && fieldValue !== "") {
          changes[fieldName] = fieldValue;
        }
      });
      return changes;
    },
    disableProtected() {
      return this.role !== "admin";
    },
    disableSave() {
      const hasChanges =
        !this.item.isNew && Object.keys(this.changes).length > 0;
      const newAndPrepared =
        this.item.isNew && this.item.Fulltitle && this.item.Inn;
      return !hasChanges && !newAndPrepared;
    },
    disableDelete() {
      return this.role != "admin" || this.item.isNew;
    },
  },
  methods: {
    toggle(newValue = false) {
      if (newValue === false) {
        this.$emit("update:item", undefined);
      }
    },
    saveItem() {
      if (!this.item.isNew) {
        this.$emit("save", this.changes);
      } else {
        this.$emit("create", { ...this.item, ...this.changes });
      }
      this.toggle(false);
    },
    deleteItem() {
      this.$emit("delete", this.item);
    },
  },
  mounted() {
    if (!this.item.isNew) {
      (async () => {
        const {
          success,
          message,
          data: responseData,
        } = await this.$http.getCompanyById(this.item.Id);
        this.$emit("update:item", success ? responseData : undefined);
      })();
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-form {
  @include flex(column, flex-start, stretch);
  > div {
    @include flex(row, space-between, center);
    > span {
      margin-right: 10px;
    }
  }
}
.modal-buttons {
  @include flex(row, space-between, center, wrap);
}
</style>
