<template>
  <div id="app">
    <router-view />
  </div>
  <div id="modal-wrapper"></div>
  <notifications />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss" scoped>
#app {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 1;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $mo-var_main_text-color;
  font-size: $mo-var_main_font-size;
  line-height: $mo-var_main_line-height;
  overflow-y: auto;

  >* {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
  }
}

#modal-wrapper {
  z-index: 2;
}
</style>

<style lang="scss">
html,
body {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
</style>
