import { createStore } from 'vuex'
import user from './modules/user'
import ranges from './modules/ranges'
import policy from './modules/policy'
import call_center from './modules/callCenter'
import cache from './modules/cache'



export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    ranges,
    policy,
    call_center,
    cache,
  },
  strict: true
})
