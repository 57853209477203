<template>
  <div class="pg-root">
    <div @click="dec(e)">
      <svg height="30" width="25" :class="{ 'pg-block-disabled': currentPage <= 0, 'pg-block': currentPage > 0 }">
        <polygon points="5,15, 20,0 20,30" />
      </svg>
    </div>
    <div class="pg-font">
      <div>{{ currentPage + 1 }}</div>
    </div>
    <div @click="inc(e)">
      <svg height="30" width="25" :class="{ 'pg-block-disabled': maxPage, 'pg-block': !maxPage }">
        <polygon points="5,0 20,15 5,30" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "mo-paginator-2",
  emits: ["pageChange"],
  props: {
    currentPage: {
      type: Number,
    },
    maxPage: { type: Boolean }
  },
  data() {
    return {
      nextDisable: false,
      prevDisable: true,
    }
  },
  mounted() {

  },
  methods: {
    dec(e) {
      if (this.currentPage > 0) {
        this.$emit("pageChange", this.currentPage - 1);
      }
    },
    inc(e) {
      if (!this.maxPage) {
        this.$emit("pageChange", this.currentPage + 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pg-root {
  @include flex(row, flex-end, stretch);
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  color: #333333;
  cursor: pointer;
  align-items: right;
  display: flex;
  border-color: black;
  height: 30px;
  max-height: 30px;
}

.pg-font {
  width: 40px;
  margin-top: 2px;
  padding: 0px;
  padding-right: 2px;
  font-size: 22px;
  font-style: bold;
}

.pg-block {
  margin: 0px;
  padding: 0px;
  fill: #b5bfc9;
  stroke: #7a8c9e;
  stroke-width: 2;
  transition: all 350ms;
}

.pg-block:active {
  fill: #7a8c9e;
  stroke: #b5bfc9;
}

.pg-block-disabled {
  fill: #f3f3f3;
  stroke: #d0d0d0;
}

.pg-block-disabled:active {
  fill: #f3f3f3;
  stroke: #d0d0d0;
}
</style>
