<template>
  <teleport to="#modal-wrapper">
    <div class="mo-modal-root">
      <div />
      <div>
        <div>
          <div>
            {{ title }}
            <mo-icon name="close" @click="$emit('toggle', false)" />
          </div>
          <div>
            <slot />
          </div>
          <div>
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "mo-modal",
  emits: ["toggle"],
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.mo-modal-root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
  > div:first-child {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #333333;
    opacity: 0.7;
    z-index: 1;
  }
  > div:last-child {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    overflow-y: auto;
    > div {
      @include flex(column, flex-start, stretch);
      @include shadow();
      margin: 70px auto;
      min-width: 250px;
      width: 80%;
      max-width: 550px;
      border-radius: 4px;
      padding: 0 15px;
      background-color: #fafafa;
      > div {
        min-height: 50px;
        &:first-child {
          border-bottom: 1px solid #d9d9d9;
          @include flex(row, space-between, center);
          padding: 15px;
          font-weight: bold;
          font-size: 16px;
          line-height: 16px;
          > svg {
            cursor: pointer;
          }
        }
        &:nth-child(2) {
          border-bottom: 1px solid #d9d9d9;
          padding: 20px 15px 10px 15px;
          > div {
            width: 100%;
            height: 100%;
          }
        }
        &:last-child {
          padding: 15px;
          > div {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
