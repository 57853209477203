<template>
  <mo-modal @toggle="cancel" title="Подтвердите действие">
    <div class="modal-form">
      <h3>{{ text || "Вы уверены? Вы прям точно-точно уверены?" }}</h3>
    </div>
    <template v-slot:footer>
      <div class="modal-buttons">
        <mo-button @click="cancel" type="success"> Оставить как было </mo-button>
        <mo-button @click="submit" type="alert"> Подтвердить </mo-button>
      </div>
    </template>
  </mo-modal>
</template>

<script>
export default {
  name: "mo-confirmation-modal",
  emits: ["cancel", "submit"],
  props: {
    text: {
      type: String,
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-form {
  @include flex(column, flex-start, stretch);
}
.modal-buttons {
  @include flex(row, space-between, center, wrap);
}
</style>
