<template>
  <div class="paginator-root">
    <div v-if="start" @click="set(1)">1</div>
    <div v-if="afterStart">...</div>
    <div v-if="currentPageBefore" @click="set(currentPage - 1)">
      {{ currentPage - 1 }}
    </div>
    <div @click="set(currentPage)" class="current">{{ currentPage }}</div>
    <div v-if="currentPageAfter" @click="set(currentPage + 1)">
      {{ currentPage + 1 }}
    </div>
    <div v-if="beforeEnd">...</div>
    <div v-if="end" @click="set(count)">{{ count }}</div>
  </div>
</template>

<script>
export default {
  name: "mo-paginator",
  emits: ["change"],
  props: {
    count: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  computed: {
    currentPage() {
      return Math.min(this.page, this.count);
    },
    currentPageBefore() {
      return this.currentPage - 1 > 0;
    },
    currentPageAfter() {
      return this.currentPage + 1 <= this.count;
    },
    start() {
      let allocated = 1;
      this.currentPageBefore && allocated++;
      this.currentPageAfter && allocated++;
      return allocated < this.count && this.currentPage - 2 > 0;
    },
    end() {
      let allocated = 1;
      this.currentPageBefore && allocated++;
      this.currentPageAfter && allocated++;
      this.start && allocated++;
      return allocated < this.count && this.currentPage + 2 <= this.count;
    },
    afterStart() {
      let allocated = 1;
      this.currentPageBefore && allocated++;
      this.currentPageAfter && allocated++;
      this.start && allocated++;
      this.end && allocated++;
      return allocated < this.count && this.currentPage - 3 > 0;
    },
    beforeEnd() {
      let allocated = 1;
      this.currentPageBefore && allocated++;
      this.currentPageAfter && allocated++;
      this.start && allocated++;
      this.end && allocated++;
      this.afterStart && allocated++;
      return allocated < this.count && this.currentPage + 3 <= this.count;
    },
  },
  methods: {
    set(newCurrentPage) {
      this.$emit("change", newCurrentPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.paginator-root {
  @include flex(row, flex-end, stretch);
  height: 30px;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 0;
  > div {
    padding: 5px 10px;
    font-size: 20px;
    font-weight: bold;
    vertical-align: middle;
    text-align: center;
    color: #333333;
    cursor: pointer;
    background-color: lighten($mo-var_main_panel-background-color, 55%);
    &:hover {
      background-color: lighten($mo-var_main_panel-background-color, 45%);
    }
    &:not(:last-child) {
      border-right: 2px solid $mo-var_main_background-color;
    }
    &.current {
      background-color: lighten($mo-var_main_panel-background-color, 35%);
    }
  }
}
</style>
