<template>
  <div class="mo-loader-root">
    <mo-icon class="spinner" name="spin" />
  </div>
</template>

<script>
export default {
  name: "mo-loader",
};
</script>

<style lang="scss" scoped>
.mo-loader-root {
  background-color: transparent;
  min-width: 40px;
  min-height: 40px;
  @include flex();
  color: #008cdb;

  > svg {
    width: 40px;
    height: 40px;

    animation-name: rotation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotation {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
</style>
