<template>
  <g>
    <path
      d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"
    />
  </g>
</template>

<script>
export default {
  name: "mo-icon-chevronleft",
};
</script>