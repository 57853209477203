<template>
  <div
    class="mo-message-root"
    :class="{ alert: type === 'alert', success: type === 'success' }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "mo-message",
  props: {
    type: {
      type: String,
      default: "info",
    },
  },
};
</script>

<style lang="scss" scoped>
.mo-message-root {
  min-height: 50px;
  min-width: 300px;
  padding: 10px;
  margin: 10px 0;
  font-size: 14px;
  line-height: 16px;
  border-radius: 5px;
  border: none;
  border-left: 5px solid #1c1cb9;
  color: #1c1cb9;
  background-color: #e2e2fe;
  &.alert {
    border-left: 5px solid #b91c1c;
    color: #b91c1c;
    background-color: #fee2e2;
  }
  &.success {
    border-left: 5px solid #1cb91c;
    color: #1cb91c;
    background-color: #e2fee2;
  }
}
</style>
