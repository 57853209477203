<template>
  <mo-confirmation-modal
    v-if="showDeleteConfirmation"
    @cancel="showDeleteConfirmation = false"
    @submit="deleteItem"
    text="Вы точно хотите удалить этого сотрудника? Точно-точно?"
  />
  <mo-modal v-else @toggle="toggle" title="Действия над записью">
    <div class="modal-form">
      <div>
        <span>ID:</span>
        <mo-input-number v-model="ID" :disabled="true" />
      </div>
      <div>
        <span>Логин (почта):</span>
        <mo-input
          v-model="Login"
          placeholder="Логин"
          :disabled="disableProtected"
        />
      </div>
      <div>
        <span>Роль:</span>
        <mo-select
          v-model="Role"
          :options="['user', 'observer' ,'admin']"
          :disabled="disableProtected"
        />
      </div>
      <div>
        <span>Имя:</span>
        <mo-input
          v-model="FirstName"
          placeholder="Имя"
          :disabled="disableProtected"
        />
      </div>
      <div>
        <span>Фамилия:</span>
        <mo-input
          v-model="LastName"
          placeholder="Фамилия"
          :disabled="disableProtected"
        />
      </div>
      <div>
        <span>Возраст:</span>
        <mo-input-number v-model="Age" :disabled="disableProtected" />
      </div>
      <div>
        <span>Пол:</span>
        <mo-select
          v-model="Gender"
          :options="[
            { value: 'male', text: 'Мужской' },
            { value: 'female', text: 'Женский' },
          ]"
          :disabled="disableProtected"
        />
      </div>
      <div>
        <span>Телефон:</span>
        <mo-input
          v-model="Phone"
          placeholder="Телефон"
          :disabled="disableProtected"
        />
      </div>
      <div>
        <span>Телефон внутренний:</span>
        <mo-input
          v-model="PhoneInternal"
          placeholder="Телефон внутренний"
          :disabled="disableProtected"
          maxlength="'4'"
        />
      </div>

      <div>
        <span>Рейтинг:</span>
        <mo-input-number v-model="Rate" :min="1" :max="5" />
      </div>
      <div>
        <span>Пароль:</span>
        <mo-input
          v-model="Password"
          type="password"
          placeholder="Текущий пароль не отображается"
          :disabled="disableProtected"
          maxlength="'16'"
        />
      </div>
      <div>
        <span>Токен:</span>
        <mo-input v-model="Token" placeholder="Токен" :disabled="true" />
      </div>
    </div>
    <template v-slot:footer>
      <mo-loader v-if="loading" />
      <div v-else class="modal-buttons">
        <mo-button @click="toggle(false)" type="success"> Отменить </mo-button>
        <mo-button
          @click="setCurrentItem()"
          type="info"
          :disabled="disableSetCurrent"
        >
          Назначить текущим
        </mo-button>
        
        <mo-button @click="saveItem()" type="alert" :disabled="disableSave"
          v-if="policy['modal_employees_button_save']" id="modal_employees_button_save"
        >
          Сохранить
        </mo-button>
        <mo-button
          @click="showDeleteConfirmation = true"
          type="alert"
          :disabled="disableDelete"
            v-if="policy['modal_employees_button_delete']" id="modal_employees_button_delete"
        >
          Удалить
        </mo-button>
        
      </div>
    </template>
  </mo-modal>
</template>

<script>
export default {
  name: "mo-employees-modal",
  emits: ["update:item", "cancel", "current", "create", "save", "delete"],
  props: {
    item: {
      type: Object,
    },
    role: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      policy: this.$store.getters["policy/getPolicy"],
      changedItem: {
        ID: undefined,
        Login: undefined,
        Role: undefined,
        FirstName: undefined,
        LastName: undefined,
        Age: undefined,
        Gender: undefined,
        Phone: undefined,
        PhoneInternal: undefined,
        Rate: undefined,
        Password: undefined,
        Token: undefined,
      },
      showDeleteConfirmation: false,
    };
  },
  computed: {
    ID: {
      get() {
        return this.changedItem.ID !== undefined
          ? this.changedItem.ID
          : this.item.ID;
      },
      set(newValue) {
        return (this.changedItem.ID = newValue);
      },
    },
    Login: {
      get() {
        return (
          (this.changedItem.Login !== undefined
            ? this.changedItem.Login
            : this.item.Login) ?? ""
        );
      },
      set(newValue) {
        return (this.changedItem.Login = newValue);
      },
    },
    Role: {
      get() {
        return (
          (this.changedItem.Role !== undefined
            ? this.changedItem.Role
            : this.item.Role) ?? ""
        );
      },
      set(newValue) {
        return (this.changedItem.Role = newValue);
      },
    },
    FirstName: {
      get() {
        return (
          (this.changedItem.FirstName !== undefined
            ? this.changedItem.FirstName
            : this.item.FirstName) ?? ""
        );
      },
      set(newValue) {
        return (this.changedItem.FirstName = newValue);
      },
    },
    LastName: {
      get() {
        return (
          (this.changedItem.LastName !== undefined
            ? this.changedItem.LastName
            : this.item.LastName) ?? ""
        );
      },
      set(newValue) {
        return (this.changedItem.LastName = newValue);
      },
    },
    Age: {
      get() {
        return (
          (this.changedItem.Age !== undefined
            ? this.changedItem.Age
            : this.item.Age) ?? 0
        );
      },
      set(newValue) {
        return (this.changedItem.Age = newValue);
      },
    },
    Gender: {
      get() {
        return (
          (this.changedItem.Gender !== undefined
            ? this.changedItem.Gender
            : this.item.Gender) ?? 0
        );
      },
      set(newValue) {
        return (this.changedItem.Gender = newValue);
      },
    },
    Phone: {
      get() {
        return (
          (this.changedItem.Phone !== undefined
            ? this.changedItem.Phone
            : this.item.Phone) ?? ""
        );
      },
      set(newValue) {
        return (this.changedItem.Phone = newValue);
      },
    },
    PhoneInternal: {
      get() {
        return (
          (this.changedItem.PhoneInternal !== undefined
            ? this.changedItem.PhoneInternal
            : this.item.PhoneInternal) ?? ""
        );
      },
      set(newValue) {
        return (this.changedItem.PhoneInternal = newValue);
      },
    },
    Rate: {
      get() {
        return (
          (this.changedItem.Rate !== undefined
            ? this.changedItem.Rate
            : this.item.Rate) ?? 0
        );
      },
      set(newValue) {
        return (this.changedItem.Rate = newValue);
      },
    },
    Password: {
      get() {
        return this.changedItem.Password || "";
      },
      set(newValue) {
        if (this.changedItem.Login === undefined)
          this.changedItem.Login = this.item.Login; //При изменении пароля нужно также присылать логин
        return (this.changedItem.Password = newValue);
      },
    },
    Token: {
      get() {
        return (
          (this.changedItem.Token !== undefined
            ? this.changedItem.Token
            : this.item.Token) ?? ""
        );
      },
      set(newValue) {
        return (this.changedItem.Token = newValue);
      },
    },
    changes() {
      const changes = {};
      Object.keys(this.changedItem).forEach((fieldName) => {
        const fieldValue = this.changedItem[fieldName];
        if (fieldValue != undefined && fieldValue !== "") {
          changes[fieldName] = fieldValue;
        }
      });
      return changes;
    },
    disableProtected() {
      return this.role !== "admin";
    },
    disableSetCurrent() {
      return this.item.isNew;
    },
    disableSave() {
      const hasChanges =
        !this.item.isNew && Object.keys(this.changes).length > 0; 
      const newAndPrepared =
        this.item.isNew &&
        this.changedItem.Login &&
        this.changedItem.FirstName &&
        this.changedItem.LastName &&
        this.changedItem.Age &&
        this.changedItem.Phone &&
        this.changedItem.PhoneInternal &&
        this.changedItem.Rate &&
        this.changedItem.Password.length >= 6;
      return !hasChanges && !newAndPrepared;
    },
    disableDelete() {
      return this.role != "admin" || this.item.isNew;
    },
  },
  methods: {
    toggle(newValue = false) {
      if (newValue === false) {
        this.$emit("update:item", undefined);
      }
    },
    setCurrentItem() {
      this.$http.fillPolicy()
      this.$emit("current", this.item);
      this.toggle(false);
    },
    saveItem() {
      if (!this.item.isNew) {
        this.$emit("save", this.changes);
      } else {
        this.$emit("create", { ...this.item, ...this.changes });
      }
      this.toggle(false);
    },
    deleteItem() {
      this.$emit("delete", this.item);
    },
  },
  mounted() {
    if (!this.item.isNew) {
      (async () => {
        const {
          success,
          message,
          data: responseData,
        } = await this.$http.getEmployeeById(this.item.ID);
        this.$emit("update:item", success ? responseData : undefined);
      })();
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-form {
  @include flex(column, flex-start, stretch);
  > div {
    @include flex(row, space-between, center);
    > span {
      margin-right: 10px;
    }
  }
}
.modal-buttons {
  @include flex(row, space-between, center, wrap);
}
</style>
