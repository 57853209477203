<template>
  <g>
  <path
d="M 57.027135,1118.3385 A 114.024,104.20566 0 1 0 269.0774,1041.6555 C 167.38448,806.60573 271.81352,524.15004 533.11419,386.30832 759.64314,266.80379 1029.4942,294.72641 1209.6229,433.19327 l -283.87373,30.73566 a 114.00552,104.18875 0 1 0 26.6773,206.91886 L 1443.7903,617.8157 a 114.00552,104.18875 0 0 0 98.3867,-82.62165 l 91.4325,-409.14923 A 114.02834,104.20961 0 0 0 1410.1587,84.369309 L 1366.2665,281.18185 C 1112.4902,79.159872 730.91375,41.339357 419.10865,205.8534 63.297437,393.49733 -87.759873,783.7884 57.027135,1118.3385 Z M 1665.645,751.59407 a 114.024,104.20565 0 0 0 -212.0503,76.68292 c 101.693,235.04991 -2.7361,517.50551 -264.0367,655.34711 -226.52903,119.5046 -496.49409,91.582 -676.50878,-46.8848 l 283.87374,-30.7356 a 114.00552,104.18875 0 0 0 -26.6773,-206.919 l -491.36379,53.0322 a 114.00552,104.18875 0 0 0 -98.38676,82.6216 l -91.432423,409.1493 a 114.02833,104.2096 0 0 0 223.450823,41.6754 l 43.89211,-196.8126 c 253.77629,202.0221 635.35276,239.8425 947.15778,75.3286 355.6973,-187.6439 506.8686,-577.935 362.0816,-912.48513 z" />
  </g>
</template>

<script>
export default {
  name: "mo-icon-refrash",
};
</script>