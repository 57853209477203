<template>
  <div class="mo-auth-form">
    <header class="flex">
      <mo-logo />
      <div>
        <slot name="menu" />
      </div>
    </header>
    <section>
      <div>
        <slot name="body" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "mp-auth-form",
  props: {},
};
</script>

<style lang="scss" scoped>
.mo-auth-form {
  padding: 20px 10px;
  @media only screen and (min-width: 800px) {
    padding: 60px 100px;
  }
  > header {
    @include flex(row, space-between, center, wrap);
  }
  > section {
    @include flex(column);
    padding: 20px 10px 10px 10px;
    > div {
      max-width: 100%;
      width: 100%;
      @include flex(column, flex-start, stretch);
      @media only screen and (min-width: 600px) {
        padding-top: 80px;
        max-width: 500px;
      }
    }
  }
}
</style>

<style lang="scss">
.mo-auth-form {
  a {
    text-decoration: none;
    color: #008cdb;
    font-size: 18px;
    line-height: 22px;
    &:hover {
      color: darken(#008cdb, 15%);
    }
  }
  > header > div > a {
    text-decoration: underline;
    margin: 20px;
  }

  > section > div {
    > h1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 30px;
      @media only screen and (min-width: 600px) {
        font-size: 48px;
        line-height: 52px;
      }
      text-align: center;
      color: #000000;
    }
    > a {
      margin: 10px 0;
    }
  }
}
</style>
