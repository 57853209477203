<template>
  <g>
    <path
      d="m 1333.1238,80.297305 c 39.78,0 77.9325,15.803257 106.0649,43.934285 l 212.13,212.13146 c 28.1325,28.13102 43.9351,66.28351 43.9351,106.06652 V 1505.2974 c 0,124.2674 -100.7325,224.9999 -224.9999,224.9999 H 270.25378 c -124.26367,0 -224.99998,-100.7325 -224.99998,-224.9999 V 305.29732 c 0,-124.26377 100.73631,-225.000015 224.99998,-225.000015 z M 270.25378,230.29731 c -41.42092,0 -74.99992,33.579 -74.99992,75.00001 V 1505.2974 c 0,41.4224 33.579,74.9999 74.99992,74.9999 h 75.00014 v -450.0001 c 0,-124.2674 100.73609,-224.99989 224.99999,-224.99989 h 599.99969 c 124.2676,0 225.0001,100.73249 225.0001,224.99989 v 450.0001 h 75.0002 c 41.4225,0 74.9999,-33.5775 74.9999,-74.9999 V 517.42956 c 0,-39.783 -15.8026,-77.93551 -43.9352,-106.06651 L 1364.1888,274.23156 c -28.1326,-28.13098 -66.285,-43.93425 -106.0649,-43.93425 h -12.8702 v 150 c 0,124.26375 -100.7324,224.99998 -224.9999,224.99998 H 720.25373 c -124.26368,0 -224.99997,-100.73623 -224.99997,-224.99998 v -150 z M 1245.2537,1580.2973 v -450.0001 c 0,-41.4224 -33.5775,-74.9999 -75.0001,-74.9999 H 570.25391 c -41.42116,0 -75.00015,33.5775 -75.00015,74.9999 v 450.0001 z M 645.2538,230.29731 h 449.9998 v 150 c 0,41.42099 -33.5774,75 -74.9998,75 H 720.25373 c -41.42093,0 -74.99993,-33.57901 -74.99993,-75 z" />
  </g>
</template>

<script>
export default {
  name: "mo-icon-save",
};
</script>