<template>
  <button class="mo-button-root" :class="classObject" @click="onClick">
    <mo-icon class="spinner" v-if="loading" name="spin" :color="color" />
    <slot v-else />
  </button>
</template>

<script>
export default {
  name: "mo-button",
  props: {
    width: {
      type: String,
      default: '200px'
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "",
    },
  },
  emits: ["click"],
  computed: {
    types() {
      return this.type ? this.type.split(" ") : [];
    },
    classObject() {
      if (!this.types.length) return {};
      const classes = {};
      this.types.forEach((type) => (classes[type] = true));
      classes.disabled = this.disabled || this.loading;
      return classes;
    },
    color() {
      return this.classObject["empty"] ? "black" : "white";
    },
  },
  methods: {
    onClick(event) {
      event.preventDefault();
      event.stopPropagation();
      !this.disabled && this.$emit("click", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.mo-button-root {
  height: 34px;
  min-width: v-bind(width);
  padding: 5px;
  margin: 5px 0;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  border-radius: 5px;
  color: #eeeeee;
  background-color: #008cdb;
  border-style: solid;
  border-width: 0;
  border-color: darken(#008cdb, 10%);
  cursor: pointer;

  &.large {
    height: 50px;
    font-size: 20px;
    line-height: 24px;
    padding: 10px;
    margin: 10px 0;
  }

  .spinner {
    animation-name: rotation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &.alert {
    background-color: #b91c1c;
    border-color: darken(#b91c1c, 10%);
  }
  &.success {
    background-color: #1cb91c;
    border-color: darken(#1cb91c, 10%);
  }

  &.empty {
    background-color: transparent;
    border-style: solid;
    border-width: 3px;
    color: #222222;
  }

  &:hover {
    background-color: darken(#008cdb, 10%);
  }
  &.alert:hover {
    background-color: darken(#b91c1c, 10%);
  }
  &.success:hover {
    background-color: darken(#1cb91c, 10%);
  }

  &.disabled {
    background: #666666;
    border-color: #666666;
    cursor: not-allowed;
    &:hover {
      background: #666666;
      border-color: #666666;
    }
    &.empty {
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
</style>
