<template>
  <div class="mo-grafana-frame-root">
    <mo-datetime-range v-model="dateRange" />
    <iframe :src="src" />
  </div>
</template>

<script>
export default {
  name: "mo-grafana-frame",
  model: {
    prop: "modelValue",
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    src() {
      const { from, to } = this.dateRange;
      var now=new Date();
      console.log("******", new Date(from.getTime()), new Date(to.getTime()))
      return this.url + `&from=${from.getTime()-now.getTimezoneOffset()*1000*60}&to=${to.getTime()-now.getTimezoneOffset()*1000*60}`;
      //return this.url + `&from=${from.getTime()}&to=${to.getTime()}`;
    },
    dateRange: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mo-grafana-frame-root {
  @include flex(column, flex-start, stretch);
  > * {
    &:first-child {
      flex: 0 0 auto;
    }
    &:last-child {
      flex: 1 1 auto;
      border-radius: 5px;
      background-color: #b5bfc9;
      border: 0px;
    }
  }
}
</style>
