<template>
  <g>
    <path 
        d="m 429.00383,560.30389 c 188.00882,0 340.47235,150.36338 340.47235,335.78245 0,185.41906 -152.46353,335.78256 -340.47235,335.78256 -188.00884,0 -340.472377,-150.3635 -340.472377,-335.78256 0,-185.41907 152.463537,-335.78245 340.472377,-335.78245 z m 757.34667,810.24301 c -4.9709,20.2142 -8.3074,41.0326 -8.3074,62.7913 0,148.3488 121.9571,268.6261 272.3777,268.6261 150.4209,0 272.378,-120.2773 272.378,-268.6261 0,-148.3486 -121.9571,-268.6259 -272.378,-268.6259 -79.33,0 -150.0801,33.9812 -199.8572,87.3705 L 876.86116,1055.5159 c -15.66173,42.7115 -37.38386,82.5353 -64.21308,118.464 z M 1722.7988,358.83442 c 0,-148.34869 -121.9571,-268.625966 -272.378,-268.625966 -150.4206,0 -272.3777,120.277276 -272.3777,268.625966 0,21.7587 3.3365,42.57722 8.3074,62.79131 L 812.64808,618.19278 c 26.89731,35.92872 48.55135,75.68537 64.21308,118.46405 L 1250.5636,540.08978 c 49.7771,53.38941 120.5272,87.3706 199.8572,87.3706 150.4209,0 272.378,-120.27727 272.378,-268.62596 z"
    />
  </g>
</template>

<script>
    export default {
    name: "mo-icon-copylink",
    };
</script>