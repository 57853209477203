<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="name"
    role="presentation"
    @click="$emit('click', $event)"
  >
    <g :fill="color">
      <component :is="icon" />
    </g>
  </svg>
</template>

<script>
import * as Icons from "./icons";

const components = {};
const meta = {};
Object.keys(Icons).forEach((name) => {
  const renderName = `mo-icon-${name.toLowerCase()}`;
  meta[renderName] = Icons[name].meta || {};
  components[renderName] = Icons[name];
});

export default {
  name: "mo-icon",
  props: {
    name: {
      type: String,
      default: "help",
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    color: {
      type: String,
      default: "currentColor",
    },
  },
  data() {
    return {
      meta,
    };
  },
  components,
  computed: {
    icon() {
      return `mo-icon-${this.name}`;
    },
    viewBox() {
      const { meta, icon } = this;
      return `0 0 ${meta[icon].width || 1792} ${meta[icon].height || 1792}`;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px;
}
</style>