
<template>
  <mo-modal-big :title="title" @toggle="this.$emit('hideModal')">
    <div class="modal-form">

      <div class="tabs">
        <div class="tabs-header">
          <button class="tabs-btn" v-for="tab in tabs" :key="tab.id" @click="activeTab = tab.id"
            :class="{ 'active': activeTab === tab.id }">{{ tab.title }} </button>
        </div>
        <div class="tabs-body">
          <div class="tabs-body-item">
            <call-center-tab-1 v-show="activeTab === 1" :companyItem="item" />
            <call-center-tab-2 v-show="activeTab === 2" :companyItem="item" />
            <call-center-tab-3 v-show="activeTab === 3" :companyItem="item" />
          </div>
        </div>
      </div>
    </div>
  </mo-modal-big>
</template>

<script>
import CallCenterTab1 from '../views/CallCenterTab1.vue';
import CallCenterTab2 from '../views/CallCenterTab2.vue';
import CallCenterTab3 from '../views/CallCenterTab3.vue';


export default {
  components: {
    CallCenterTab1,
    CallCenterTab2,
    CallCenterTab3,
  },
  name: "mo-call-center-modal",
  emits: ["update:item", "hideModal"],
  props: {
    item: {
      type: Object,
    },
    role: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      activeTab: 1,
      tabs: [
        { id: 1, title: 'Карточка клиента' },
        { id: 2, title: 'Данные по РКП' },
        { id: 3, title: 'Данные по лотам' }
      ],
      policy: this.$store.getters["policy/getPolicy"],
      showDeleteConfirmation: false,
    };
  },
  computed: {
    title() {
      return "Карточка компании: № " + this.item.Id + " " +
        " \"" + this.item.Fulltitle +
        " \" ИНН: " + this.item.Inn +
        " Орг. код: " + this.item.OrgCode + " ФИО Директора: " + this.item.DirectorName +
        " Телефон:" + this.item.Phone;
    },
  },
  methods: {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.modal-form {

  .tabs {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .tabs-header {
    width: 100%;
    border-bottom: 3px solid #eee;
  }

  .tabs-body {
    width: 100%;
    height: 100%;
  }

  .tabs-btn {
    font-size: 18px;
    color: #97a4ab;
    border: none;
    display: inline-block;
    background: #fff;
    padding: 10px 25px;
    text-align: center;
    cursor: pointer;
    margin-bottom: -3px;
    border-bottom: 3px solid #eee;
  }

  .tabs-btn.active {
    color: #59a4cf;
    border-bottom: 3px solid #59a4cf;
  }

  .tabs-body-item {
    width: 100%;
    padding: 20px 0;
    flex-grow: 1;
  }
}
</style>
