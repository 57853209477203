<template>
  <div class="mo-input-root">
    <div class="decrement" :class="{ disabled, focused }" @click="decrement">-</div>
    <input
      :value="modelValue"
      type="text"
      @input="update($event.target.value)"
      @focus="focused = true"
      @blur="focused = false"
      :disabled="disabled"
    />
    <div class="increment" :class="{ disabled, focused }" @click="increment">+</div>
  </div>
</template>

<script>
export default {
  name: "mo-input-number",
  model: {
    prop: "modelValue",
  },
  emits: ["update:modelValue"],
  props: {
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    update(newValue) {
      if (this.disabled) {
        return;
      }
      const newValueAsNumber = Number(newValue);
      if (isNaN(newValueAsNumber) || String(newValueAsNumber) !== String(newValue)) {
        this.$forceUpdate();
        return;
      }
      if (this.max && newValueAsNumber > this.max) {
        this.$forceUpdate();
        return;
      }
      if (this.min && newValueAsNumber < this.min) {
        this.$forceUpdate();
        return;
      }
      this.$emit("update:modelValue", newValueAsNumber);
    },
    decrement() {
      this.update(this.modelValue-1);

    },
    increment() {
      this.update(this.modelValue+1);
    },
  },
};
</script>

<style lang="scss" scoped>
.mo-input-root {
  @include flex(row, space-between, stretch);
  position: relative;
  min-width: 300px;
  margin: 0 0 10px 0;
  &.small {
    min-width: 100px;
  }
  > .decrement {
    @include flex();
    width: 40px;
    flex: 0 0 auto;
    border: 1px solid #aaaaaa;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: transparent;
    font-size: 20px;
    line-height: 24px;
    transition: border-color linear 0.2s;
    cursor: pointer;
    &.disabled {
      background-color: #eaeaea;
      cursor: not-allowed;
    }
    &.focused {
      border-color: #008cdb;
    }
  }
  > input {
    flex: 1 1 auto;
    outline-width: 0;
    outline-color: transparent;
    border-radius: 0;
    background: transparent;
    color: #2c3e50;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 10px;
    margin: 0;
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
    border-left: none;
    border-right: none;
    transition: border-color linear 0.2s;
    &:disabled {
      background-color: #eaeaea;
      cursor: not-allowed;
    }
    &:focus {
      border-color: #008cdb;
    }
  }

  > .increment {
    @include flex();
    width: 40px;
    flex: 0 0 auto;
    border: 1px solid #aaaaaa;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: transparent;
    font-size: 20px;
    line-height: 24px;
    transition: border-color linear 0.2s;
    cursor: pointer;
    &.disabled {
      background-color: #eaeaea;
      cursor: not-allowed;
    }
    &.focused {
      border-color: #008cdb;
    }
  }
}
</style>
