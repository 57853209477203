<template>
  <div class="mo-datetime-range-root">
    <mo-icon name="clock" />
    <datepicker
      :modelValue="modelValue.from"
      @update:modelValue="setFrom"
      :locale="ru"
      :upperLimit="limitFrom.to"
      :lowerLimit="limitFrom.from"
    />
    <mo-icon name="arrowsh" />
    <datepicker
      :modelValue="modelValue.to"
      @update:modelValue="setTo"
      :locale="ru"
      :upperLimit="limitTo.to"
      :lowerLimit="limitTo.from"
    />
  </div>
</template>

<script>
import { ru } from "date-fns/locale";

export default {
  name: "mo-datetime-range",
  model: {
    prop: "modelValue",
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ru,
      from: new Date(),
    };
  },
  computed: {
    limitFrom() {
      return {
        to: this.modelValue.to, // Disable all dates up to specific date
        from: new Date(2000, 0, 1), // Disable all dates after specific date
      };
    },
    limitTo() {
      return {
        to: new Date(2070, 0, 1), // Disable all dates up to specific date
        from: this.modelValue.from, // Disable all dates after specific date
      };
    },
  },
  methods: {
    setFrom(newValue) {
      const from = new Date(newValue.getFullYear(), newValue.getMonth(), newValue.getDate(), 0,0,0)
      this.$emit("update:modelValue", {
        from: from,
        to: this.modelValue.to,
      });
    },
    setTo(newValue) {
      const to = new Date(newValue.getFullYear(), newValue.getMonth(), newValue.getDate(), 23, 59, 59)
      this.$emit("update:modelValue", {
        from: this.modelValue.from,
        to: to,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mo-datetime-range-root {
  @include flex(row, flex-start, center);
  margin: 5px;
  > svg {
    margin: 0 10px;
  }
}
</style>

<style lang="scss">
.mo-datetime-range-root {
  input {
    width: 120px;
    cursor: pointer;
    outline-width: 0;
    outline-color: transparent;
    border-radius: 0;
    background: transparent;
    color: #2c3e50;
    font-size: 16px;
    line-height: 24px;
    padding: 5px;
    margin: 0;
    border: none;
    border-bottom: 1px #aaaaaa solid;
  }
}
</style>
