<template>
  <mo-modal @toggle="toggle" title="Действия над записью">
    <div class="modal-form">
      <div>
        <span>ID:</span>
        <span>{{ item.ID }}</span>
      </div>
      <div>
        <span>Создан:</span>
        <span>{{ item.CreatedAt }}</span>
      </div>
      <div>
        <span>Рейтинг:</span>
        <span>{{ item.Rate }}</span>
      </div>
      <div>
        <span>Клиент:</span>
        <span>{{ item.Client }}</span>
      </div>
      <div>
        <span>Компания:</span>
        <span>{{ item.Company }}</span>
      </div>
      <div>
        <span>Отзыв:</span>
        <span>{{ item.Text }}</span>
      </div>
    </div>
    <template v-slot:footer>
      <mo-loader v-if="loading" />
      <div v-else class="modal-buttons">
        <mo-button @click="toggle(false)" type="success"> Отменить </mo-button>
      </div>
    </template>
  </mo-modal>
</template>

<script>
export default {
  name: "mo-feedback-modal",
  emits: ["update:item", "cancel"],
  props: {
    item: {
      type: Object,
    },
    role: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle(newValue = false) {
      if (newValue === false) {
        this.$emit("update:item", undefined);
      }
    },
  },
  mounted() {
    if (!this.item.isNew) {
      (async () => {
        const {
          success,
          message,
          data: responseData,
        } = await this.$http.getFeedbackByID(this.item.ID);
        this.$emit("update:item", success ? responseData : undefined);
      })();
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-form {
  @include flex(column, flex-start, stretch);
  > div {
    @include flex(row, space-between, center);
    > span {
      margin-right: 10px;
    }
  }
}
.modal-buttons {
  @include flex(row, space-between, center, wrap);
}
</style>
