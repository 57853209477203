<template>
  <label class="mo-checkbox-root">
    <input
      type="checkbox"
      :checked="modelValue"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.checked)"
    />
    <slot />
  </label>
</template>

<script>
export default {
  name: "mo-checkbox",
  model: {
    prop: "modelValue",
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.mo-checkbox-root {
  margin: 10px 0;
  font-size: 16px;
  line-height: 18px;
  vertical-align: middle;
  > * {
    font-size: 16px;
    line-height: 18px;
    vertical-align: middle;
  }
  > input {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    margin-right: 4px;
    &:disabled {
      background-color: #eaeaea;
      cursor: not-allowed;
    }
  }
  > a {
    text-decoration: none;
    color: #008cdb;
    font-size: 18px;
    line-height: 18px;
    &:hover {
      color: darken(#008cdb, 15%);
    }
  }
}
</style>
