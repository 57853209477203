<template>
  <div class="mo-logo-root">
    <img class="h-full" alt="logo" src="@/assets/logo.png" />
  </div>
</template>

<script>
export default {
  name: "mo-logo",
};
</script>

<style lang="scss" scoped>
.mo-logo-root {
  height: 80px;
  > img {
    display: block;
    height: 100%;
  }
}
</style>
