const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {

    install(app, _options) {
        const utils = {
            emailIsValid(email) {
                return emailRegExp.test(email)
            },

            passwordIsValid(password) {
                return password.trim().length >= 6
            }
        }

        app.config.globalProperties.$utils = utils
        app.provide('utils', utils)
    },
}