const now = new Date();
//const oneMonthInMs = 1000 * 60 * 60 * 24 * 31;
const from = new Date(now.getFullYear(), now.getMonth()-1, now.getDate(), 0, 0, 0)
const to = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59)

const state = () => ({
  all: {
    reportEmployeesRange: { from, to, },
    reportSumCompaniesRange: { from, to, },
    reportSumCompanyByUserIdRange: { from, to, },
    reportSumProcedureByUserIdRange: { from, to, },
    reportSumProceduresRange: { from, to, },
    proceduresRange: { from, to, },
    feedbacksRange: { from, to, },
    employeeHuntingRange: { from, to, },
    companiesRange: { from, to, }
  }
})

// getters
const getters = {
  getReportEmployeesRange: state => state.all.reportEmployeesRange,
  getReportSumCompaniesRange: state => state.all.reportSumCompaniesRange,
  getReportSumCompanyByUserIdRange: state => state.all.reportSumCompanyByUserIdRange,
  getReportSumProcedureByUserIdRange: state => state.all.reportSumProcedureByUserIdRange,
  getReportSumProceduresRange: state => state.all.reportSumProceduresRange,
  getProceduresRange: state => state.all.proceduresRange,
  getFeedbacksRange: state => state.all.feedbacksRange,
  getEmployeeHuntingRange: state => state.all.employeeHuntingRange,
  getCompaniesRange: state => state.all.companiesRange
}

// actions
const actions = {}

// mutations
const mutations = {
  CLEAR_ALL(state) {
    state.all = {
      reportEmployeesRange: { from, to, },
      reportSumCompaniesRange: { from, to, },
      reportSumCompanyByUserIdRange: { from, to, },
      reportSumProcedureByUserIdRange: { from, to, },
      reportSumProceduresRange: { from, to, },
      proceduresRange: { from, to, },
      feedbacksRange: { from, to, },
      employeeHuntingRange: { from, to, },
      companiesRange: { from, to, }
    }
  },
  SET_REPORT_EMPLOYEES_RANGE(state, range) {
    state.all.reportEmployeesRange = range
  },
  SET_REPORT_SUM_COMPANIES_RANGE(state, range) {
    state.all.reportSumCompaniesRange = range
  },
  SET_REPORT_SUM_COMPANY_BY_USER_ID_RANGE(state, range) {
    state.all.reportSumCompanyByUserIdRange = range
  },
  SET_REPORT_SUM_PROCEDURE_BY_USER_ID_RANGE(state, range) {
    state.all.reportSumProcedureByUserIdRange = range
  },
  SET_REPORT_SUM_PROCEDURES_RANGE(state, range) {
    state.all.reportSumProceduresRange = range
  },
  SET_PROCEDURES_RANGE(state, range) {
    state.all.proceduresRange = range
  },
  SET_FEEDBACKS_RANGE(state, range) {
    state.all.feedbacksRange = range
  },
  SET_EMPLOYEE_HUNTING_RANGE(state, range) {
    state.all.employeeHuntingRange = range
  },
  SET_COMPANIES_RANGE(state, range) {
    state.all.companiesRange = range
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

