<template>
  <div class="lot-root">
    <div class="ref-procedures-crm-block">
      <div style="height: 40em;">
        <div v-if="loadingRefProceduresCrm" class="loader">
          <mo-loader />
        </div>
        <mo-table v-else :columns="columnsRefProceduresCrm" :items="itemsRefProceduresCrm"
          @update:current="changeCurrentItemRefProceduresCrm" />
      </div>
      <div class="panel">
        <mo-paginator-2 v-bind:currentPage="currentPageRefProceduresCrm" :maxPage="maxPageRefProceduresCrm"
          @pageChange="pageChangeRefProceduresCrm" />
      </div>
    </div>
  </div>
</template>

<script>
const columnsRefProceduresCrm = [
  { name: "id", title: "№", width: 100 },
  { name: "type", title: "Тип процедуры", width: 100 },
  { name: "title", title: "Наименование", width: 100 },
  { name: "inserted_at", title: "Дата", width: 100, type: "DateTime" },
  { name: "status", title: "Статус", width: 100 },
  { name: "price", title: "Цена", width: 100 },
  { name: "amount", title: "Количество", width: 100 },
];

const typeDecode = {
  "ad": "Магазин",
  "tender": "Тендер",
  "selection": "Отбор",
  "reduction": "Аукцион",
  "request": "Запрос",
  "agreement_request": "Запрос по РС"
};

const rowCount = 17;

export default {
  name: 'call-center-tab3',
  props: {
    companyItem: {
      type: Object,
    }
  },
  data() {
    return {
      //Переменные для верхней таблицы пользоатели компании
      columnsRefProceduresCrm,
      itemsRefProceduresCrm: [],
      loadingRefProceduresCrm: false,
      currentPageRefProceduresCrm: 0,
      maxPageRefProceduresCrm: false,
      limitRefProceduresCrm: rowCount,
      dateBeginRefProceduresCrm: undefined,
      dateEndRefProceduresCrm: undefined,
      queryRefProceduresCrm: "",
      opTypeRefProceduresCrm: "",
      currentItemRefProceduresCrm: {},
      statusLotsMap: [],
      //offsetRefProceduresCrm: 0,
    }
  },
  methods: {
    changeCurrentItemRefProceduresCrm(item) {
      // https://test.xt-xarid.uz:8443/
      //https://xt-xarid.uz/
      let url = 'https://'+process.env.VUE_APP_XT_XARID_HOST+'/procedure/' + item.id + '/core';
      window.open(url, '_blank');
      this.currentItemRefProceduresCrm = item;
    },
    async pageChangeRefProceduresCrm(event) {
      this.currentPageRefProceduresCrm = event;
      this.loadRefProceduresCrm();
    },
    decodeStatus(d) {
      //let d = this.itemsRefProceduresCrm;
      d.forEach((item, i) => {
        d[i]['status'] = this.statusLotsMap[item['status']] || item['status'];
        d[i]['type'] = typeDecode[item['type']] || item['type'];
        d[i]['price'] = item['data']['price'] || 0;
        d[i]['amount'] = item['data']['amount'] || 0;
      })
      return d;
    },
    async loadRefProceduresCrm() {
      this.loadingRefProceduresCrm = true;

      const { success, message, data: responseData } = await this.$http.getRefProceduresCrmById(
        this.companyItem.Id,
        this.limitRefProceduresCrm,
        this.currentPageRefProceduresCrm * rowCount,
      );

      let d = responseData?.result || [];
      this.itemsRefProceduresCrm = this.decodeStatus(d);

      if (this.itemsRefProceduresCrm.length > 0) this.maxPageRefProceduresCrm = false;
      if (this.itemsRefProceduresCrm.length < rowCount) this.maxPageRefProceduresCrm = true;

      this.loadingRefProceduresCrm = false;
    },
    async loadStatusLots() {
      const { success, message, data: responseData } = await this.$http.getRefStatusLots();
      if (success) {
        let d = responseData || [];
        let b = [];

        d.forEach(e => {
          b[e['Code']] = e['Name'];
        });

        this.statusLotsMap = b;
        this.itemsRefProceduresCrm = this.decodeStatus(this.itemsRefProceduresCrm);
      }
    },
  },
  mounted() {
    this.loadStatusLots();
    this.loadRefProceduresCrm();
  },
}
</script>

<style lang="scss" scoped>
.lot-root {
  width: 100%;
  height: 100%;

  .ref-procedures-crm-block {
    width: 100%;
    height: 100%;
    @include flex(column, flex-start, stretch);

    .panel {
      @include flex(column, space-between, right);
    }

    .loader {
      padding: 50px 0;
      @include flex();
    }
  }

  .panel-oneline {
    @include flex(row, space-between, right);
    margin-top: 40px;
  }
}
</style>
