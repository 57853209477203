import { createApp } from 'vue'

import AppComponent from './App.vue'

import API from '@/plugins/api.plugin'
import AUTH from '@/plugins/auth.plugin'
import DIRECTIVES from '@/plugins/directives.plugin'
import UTILS from '@/plugins/utils.plugin'

import createRouter from '@/router'
import store from '@/store'

import * as Components from "@/components";
import Datepicker from 'vue3-datepicker'

import Notifications from '@kyvg/vue3-notification'



const app = createApp(AppComponent)
app.config.devtools = true
app.use(store)
app.use(API, { baseURL: process.env.VUE_APP_API_END_POINT_URL, withCredentials: true })
app.use(AUTH)
app.use(createRouter(app))
app.use(DIRECTIVES)
app.use(UTILS)
app.use(Notifications)

Object.keys(Components).forEach(name => app.component(name, Components[name]))
// eslint-disable-next-line
app.component('datepicker', Datepicker)

app.mount('body')
