<template>
  <div class="mo-search-root" v-click-outside="blur">
    <div class="icons">
      <mo-icon
        :class="{ spinner: loading }"
        :name="loading ? 'spin' : 'search'"
      />
    </div>
    <input
      :value="modelValue"
      type="text"
      :placeholder="placeholder"
      @input="updateValue"
      @focus="focus"
      @keyup.enter="keyupEnter"
    />
    <div class="icons">
      <div
        v-if="modelValue"
        class="clear"
        @click="$emit('update:modelValue', '')"
      >
        <mo-icon name="close" />
      </div>
      <div
        v-if="help"
        class="help"
        @click="toggleHelp()"
        v-click-outside="closeHelp"
      >
        <mo-icon name="help" color="white" />
        <div :class="{ active: showHelp }">{{ help }}</div>
      </div>
    </div>
    <div class="gray-border" />
    <div class="blue-border" :class="{ focused }" />
  </div>
</template>

<script>
export default {
  name: "mo-search",
  model: {
    prop: "modelValue",
  },
  emits: ["update:modelValue","keyup:Enter"],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
    },
  },
  data() {
    return {
      showHelp: false,
      focused: false,
      timeout: undefined,
    };
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    closeHelp() {
      this.toggleHelp(false);
    },
    toggleHelp(newState = !this.showHelp) {
      this.showHelp = newState;
    },
    keyupEnter(key){
      this.$emit("keyup:Enter", key.target.value);
    },
    updateValue(event) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () => this.$emit("update:modelValue", event.target.value ?? ""),
        300
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.mo-search-root {
  @include flex(row, flex-start, stretch);
  position: relative;
  min-height: 34px;
  min-width: 230px;
  margin: 0 0 10px 0;
  > svg {
    &.spinner {
      animation-name: rotation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  > input {
    flex: 1 1 auto;
    outline-width: 0;
    outline-color: transparent;
    border-radius: 0;
    background: transparent;
    color: #2c3e50;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 5px 5px 5px;
    margin: 0;
    border: none;
    transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .icons {
    @include flex(row, flex-end, center);
    > .clear {
      padding: 5px;
      margin-right: 5px;
      cursor: pointer;
    }
    > .help {
      font-size: 16px;
      line-height: 24px;
      > svg {
        cursor: pointer;
        border-radius: 50%;
        background-color: #008cdb;
        vertical-align: middle;
      }
      > div {
        min-width: 240px;
        @include flex();
        display: none;
        position: absolute;
        right: 100%;
        top: 0;
        margin-right: 5px;
        margin-top: 5px;
        padding: 10px;
        background-color: lighten(#aaaaaa, 30%);
        color: #2c3e50;
        border: 1px solid lighten(#aaaaaa, 20%);
        border-radius: 5px;
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        &.active {
          display: flex;
        }
      }
    }
  }

  > .gray-border {
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 0;
    right: 0;
    height: 1px;
    border: none;
    background-color: #aaaaaa;
  }
  > .blue-border {
    position: absolute;
    bottom: 0;
    z-index: 2;
    left: 50%;
    right: 50%;
    height: 1px;
    border: none;
    background-color: #008cdb;
    transition: left 1s ease, right 1s ease;
    &.focused {
      left: 0;
      right: 0;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
</style>
